import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const getTree = () => request({
    url: businessUrl + "productCategory/select",
    method: "get",
    data: {
        
    }
})
export const list = (status,nameOrCode,pageNo,pageSize,categoty) => {
    return request({
        url: businessUrl + "product/selectPage",
        method: "post",
        data: {
            status,nameOrCode,pageNo,pageSize,categoty
        }
    })
}
export const add = (row,productPropertyList, categotyId) => {
    return request({
    url: businessUrl + "product/insert",
    method: "post",
    data: {
        ...row,
        productPropertyList,
        categotyId
    }
})}

export const selectById = (id) => {
    return request({
        url: businessUrl + "product/selectById",
        method: "get",
        params: {
            id
        }
    })
}

export const remove = (id) => {
    return request({
    url: businessUrl + "product/delete",
    method: "delete",
    params: {
        id
    }
    })
}
export const edit = (row,productPropertyList) => request({
    url: businessUrl + "product/update",
    method: "put",
    data: {
        ...row,
        productPropertyList
    }
})
export const setStatus = (row) => request({
    url: businessUrl + "product/updateStatus",
    method: "put",
    data: {
        id : row.id,
        status: row.status == 1 ? 1 : 0
    }
})
export const audit = (id,status) => request({
    url: businessUrl + "product/audit",
    method: "put",
    data: {
        id,
        auditStatus: status
    }
})
//查询存货类型
export const getType = (id) => request({
    url: businessUrl + "dict/selectByPrimaryKey",
    method: "get",
    params: {
        id
    }
})

//查询存货类型ID
export const getIdType = (id) => request({
    url: businessUrl + "productCategory/selectById",
    method: "get",
    params: {
        id
    }
})
//根据物料分类ID查属性
export const getItemByProductId = (id) => request({
    url: businessUrl + "productCategory/selectItemById",
    method: "get",
    params: {
        id
    }
})

//查询物料的定量克重
export const getGramWeight = (scaleFactor,row) => request({
    url: businessUrl + "calculation/gramWeight",
    method: "post",
    data: {
        scaleFactor,
        ...row
    }
})

//查询物料分类的信息
export const getProductCategoryDate = (id) => request({
    url: businessUrl + "productCategory/selectById",
    method: "get",
    params: {
        id
    }
})